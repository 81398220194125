export default [
  {
    title: "Dashboards",
    icon: "HomeIcon",
    children: [
      {
        title: "Statistique",
        route: "dashboard-ecommerce",
        //  icon: 'BarChart2Icon',
      },
    ],
  },
  {
    title: "Produits",
    icon: "ShoppingCartIcon",
    children: [
      {
        title: "Produits",
        route: "apps-e-commerce-shop",
      //   icon: 'DatabaseIcon'
      },
      {
        title: "Catégories",
        route: "apps-e-commerce-shop-categories",
        //    icon: 'CommandIcon'
      },
      {
        title: "Sous catégories",
        route: "apps-e-commerce-shop-sub-categories",
        //   icon: 'LifeBuoyIcon'
      },
      {
        title: "Tags",
        route: "apps-e-commerce-shop-tags",
        //   icon: 'LifeBuoyIcon'
      },
      /* {
        title: 'View',
        route: { name: 'apps-e-commerce-product-details', params: { slug: 'apple-watch-series-5-27' } },
      },
     {
        title: 'Wishlist',
        route: 'apps-e-commerce-wishlist',
      },
      {
        title: 'Checkout',
        route: 'apps-e-commerce-checkout',
      }, */
    ],
  },

  {
    title: "Boost",
    icon: "DollarSignIcon",
    children: [
      {
        title: "Produits",
        route: "boost-request-products",
        //  icon: 'DatabaseIcon',
      },
      {
        title: "Utilisateurs",
        route: "boost-request-users",
        //   icon: 'UserIcon',
      },
      {
        title: "Bannière",
        route: "boost-request-banners",
        //    icon: 'CpuIcon',
      },
      {
        title: "Bagde",
        route: "",
        //   icon: 'InboxIcon',
      },
      {
        title: "Postes",
        route: "boost-request-posts",
        //   icon: 'InboxIcon',
      },
    ],
  },
  {

    title: "Commandes",
    icon: "FileTextIcon",
    route: "commandes",

  },
  {
    title: "Utilisateurs",
    icon: "UserIcon",
    children: [
      {
        title: "Particuliers",
        route: "apps-users-list",
      },
      {
        title: "Professionels",
        route: "apps-pro-list",
      },
      {
        title: "Liste des demandes",
        route: "apps-pro-demand",
      },
    ],
    /* children: [
      {
        title: 'List',
        route: 'apps-users-list',
        icon: 'ListIcon'
      },
      {
        title: 'View',
        route: { name: 'apps-users-view', params: { id: 21 } },
        icon : 'InfoIcon'
      },
      {
        title: 'Edit',
        route: { name: 'apps-users-edit', params: { id: 21 } },
        icon: 'Edit2Icon'
      },
    ], */
  },

  {
    title: "Contactes & abonnés",
    icon: "PlusSquareIcon",
    children: [
      {
        title: "Contactes",
        route: "others-contact",
        //    icon: 'SendIcon'
      },
      {
        title: "Abonnés",
        route: "others-subscription",
        //   icon : 'BellIcon'
      },
    ],
  },
  {
    title: "Notification",
    icon: "BellIcon",
    route: "pages-notification",

  },
  {
    title: "Paramétre",
    icon: "SettingsIcon",
    route: "pages-account-setting",

  },

  /*
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Second Page',
    route: 'second-page',
    icon: 'FileIcon',
  }, */
]
